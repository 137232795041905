<template>
	<Dialog :dialog="editDialog" :dialog-width="950" @close="$emit('close', true)">
		<template v-slot:title> Edit SEO Project</template>
		<template v-slot:body>
			<div class="parent-element">
				<v-form v-model.trim="formValid" v-on:submit.stop.prevent="updateSEO">
					<v-row class="mb-3 px-4">
						<v-col lg="3" cols="12" class="my-auto py-0">
							<label class="input-label">Keyword</label>
						</v-col>
						<v-col lg="9" class="my-auto py-0">
							<v-row>
								<v-col cols="4" md="5" class="py-0">
									<v-text-field
										outlined
										hide-details
										placeholder="Keyword"
										:loading="pageLoading"
										v-model="editData.keyword_monthly"
									>
										<!-- v-on:change="dateFormated(project.customer_services[index], index)" -->
									</v-text-field>
								</v-col>
								<div class="my-auto">+</div>
								<v-col cols="4" md="5" class="py-0">
									<!-- v-model="project.customer_services[index].keyword_free" -->
									<v-text-field
										outlined
										hide-details
										placeholder="Free"
										:loading="pageLoading"
										v-model="editData.keyword_free"
									>
										<!-- v-on:keyup="dateFormated(project.customer_services[index], index)" -->
									</v-text-field>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row class="mb-3 px-4">
						<v-col lg="3" cols="12" class="my-auto py-0">
							<label class="input-label required">Duration</label>
						</v-col>
						<v-col lg="9" class="my-auto py-0">
							<v-row>
								<v-col cols="4" md="5" class="py-0">
									<v-autocomplete
										:items="months"
										item-text="text"
										item-value="value"
										hide-details
										outlined
										placeholder="Month"
										:loading="pageLoading"
										v-model="editData.total_time"
										@change="dateFormated('total_time')"
										:rules="[vrules.required(editData.total_time, 'Duration')]"
										:class="{ required: !editData.total_time }"
									>
										<!-- v-on:change="dateFormated(project.customer_services[index], index, 'seo-service')" -->
									</v-autocomplete>
								</v-col>
								<div class="my-auto">+</div>
								<v-col cols="4" md="5" class="py-0">
									<v-text-field
										outlined
										type="number"
										hide-details
										placeholder="Free"
										:loading="pageLoading"
										v-model="editData.time_free"
										@keyup="dateFormated('time_free')"
										@paste="disablePaste"
									>
										<!-- v-on:keyup="dateFormated(project.customer_services[index], index, 'seo-service')" -->
									</v-text-field>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row class="px-4">
						<v-col lg="3" cols="12" class="my-auto py-0">
							<label class="input-label">Dates</label>
						</v-col>
						<v-col lg="3" class="my-auto py-0"><label class="input-label">Start Date</label></v-col>
						<v-col lg="3" class="my-auto py-0"><label class="input-label">End Date</label></v-col>
						<v-col lg="3" class="my-auto py-0"><label class="input-label">Extend Free Date</label></v-col>
					</v-row>
					<v-row class="mb-3 px-4">
						<v-col lg="3" cols="12" class="my-auto py-0"> </v-col>
						<v-col lg="3" class="my-auto py-0">
							<DatePicker
								hide-details
								clearable
								:disabled="pageLoading"
								:loading="pageLoading"
								id="contract-date"
								placeholder="Start Date"
								v-model="editData.select_date"
								@click:clear="(editData.total_time_end = null), (editData.time_free_end = null)"
								@change="dateFormated('select_date')"
							>
								<!-- v-model="project.customer_services[index].select_date"
									v-on:change="dateFormated(project.customer_services[index], index, 'seo-service')" -->
							</DatePicker>
						</v-col>
						<v-col lg="3" class="my-auto py-0">
							<DatePicker
								hide-details
								readonly
								:disabled="pageLoading"
								:loading="pageLoading"
								id="contract-date"
								placeholder="End Date"
								v-model="editData.total_time_end"
								:min-date="editData.select_date"
							>
								<!-- v-model="project.customer_services[index].total_time_end"
									v-on:change="dateFormated(project.customer_services[index], index, 'seo-service')" -->
							</DatePicker>
						</v-col>
						<v-col lg="3" class="my-auto py-0">
							<DatePicker
								hide-details
								readonly
								:disabled="pageLoading"
								:loading="pageLoading"
								id="contract-date"
								placeholder="Extend Date"
								v-model="editData.time_free_end"
								:min-date="editData.total_time_end"
							>
								<!-- v-model="project.customer_services[index].time_free_end"
									v-on:change="dateFormated(project.customer_services[index], index, 'seo-service')" -->
							</DatePicker>
						</v-col>
					</v-row>
					<v-row class="mb-3 px-4">
						<v-col lg="3" cols="12" class="my-auto py-0">
							<label class="input-label">Remark </label>
						</v-col>

						<v-col lg="9" class="my-auto py-0">
							<TextAreaInput
								:rows="4"
								dense
								ref="comment"
								:word-limit="200"
								hide-details
								placeholder="Enter Remark..."
								:disabled="pageLoading"
								:loading="pageLoading"
								v-model="editData.remark"
							></TextAreaInput>
							<!-- v-model="project.customer_services[index].remark" -->
						</v-col>
					</v-row>
					<!-- v-for="(child_data, child_index) in data.customer_urls"
						:key="`child_index${child_index}`" -->
					<div>
						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label class="input-label required">URL</label>
							</v-col>
							<v-col lg="9" class="my-auto py-0">
								<v-text-field
									hide-details
									outlined
									placeholder="URL"
									:loading="pageLoading"
									v-model="editData.website_urls"
									:rules="[vrules.validUrl(editData.website_urls, 'URL')]"
									:class="{
										required: !editData.website_urls,
									}"
								>
									<!-- v-model="project.customer_services[index].customer_urls[child_index].website_urls" -->
								</v-text-field>
							</v-col>
						</v-row>
						<v-row class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label class="input-label required">Target Google</label>
							</v-col>
							<v-col lg="9" class="my-auto py-0 position-relative">
								<v-autocomplete
									:items="target_list"
									:loading="pageLoading"
									item-text="text"
									item-value="id"
									hide-details
									outlined
									placeholder="Target Google"
									v-model="editData.url_target_google"
									:class="{
										required: !editData.url_target_google,
									}"
								>
									<!-- v-model="project.customer_services[index].customer_urls[child_index].url_target_google" -->

									<template v-slot:selection="data">
										<div class="d-flex">
											<div class="svg-custom-width mr-2">
												<inline-svg :src="$assetURL(`media/svg/flags/${data.item.status_color}.svg`)" />
											</div>
											<div>
												{{ data.item.text }}
											</div>
										</div>
									</template>
									<template v-slot:item="data">
										<template>
											<v-list-item-content>
												<v-list-item-title>
													<div class="svg-custom-width">
														<inline-svg :src="$assetURL(`media/svg/flags/${data.item.status_color}.svg`)" />
														{{ data.item.text }}
													</div>
												</v-list-item-title>
											</v-list-item-content>
										</template>
									</template>
								</v-autocomplete>
							</v-col>
						</v-row>

						<v-row v-if="editData.url_target_google == 216" class="mb-3 px-4">
							<v-col lg="3" cols="12" class="my-auto py-0">
								<label class="input-label"></label>
							</v-col>
							<v-col lg="9" class="my-auto py-0 position-relative">
								<v-text-field
									hide-details
									outlined
									placeholder="Other Target Google"
									:loading="pageLoading"
									v-model="editData.other_target_google"
								>
									<!-- v-model="project.customer_services[index].customer_urls[child_index].website_urls" -->
								</v-text-field>
							</v-col>
						</v-row>
					</div>
				</v-form>
			</div>
		</template>
		<template v-slot:action>
			<v-btn
				class="white--text"
				:loading="pageLoading"
				:disabled="pageLoading || !formValid"
				depressed
				color="blue darken-4"
				tile
				@click="updateSEO"
			>
				Save
			</v-btn>
			<v-btn depressed tile v-on:click="closeDialog">Close </v-btn>
		</template>
	</Dialog>
</template>

<script>
import TextAreaInput from "@/view/components/TextAreaInput";
import DatePicker from "@/view/components/DatePicker";
import Dialog from "@/view/components/Dialog";
import { GET, PUT } from "@/core/services/store/request.module";
import { toSafeInteger } from "lodash";
import MomentJS from "moment-timezone";
// import { POST, GET, DELETE } from "@/core/services/store/request.module";

export default {
	name: "Update-SEOProject",
	title: "Update SEO Project",
	props: {
		editDialog: {
			type: Boolean,
			default: false,
		},
		seoId: {
			type: [Number, String],
			default: null,
		},
	},
	components: {
		TextAreaInput,
		DatePicker,
		Dialog,
	},
	data() {
		return {
			editData: {
				// id: null,
				keyword_monthly: null,
				keyword_free: null,
				total_time: null,
				time_free: null,
				select_date: null,
				total_time_end: null,
				time_free_end: null,
				remark: null,
				website_urls: null,
				url_target_google: null,
				other_target_google: null,
			},

			projectId: null,
			pageLoading: false,
			target_list: [],
			months: [
				{ value: "1", text: "1" },
				{ value: "2", text: "2" },
				{ value: "3", text: "3" },
				{ value: "4", text: "4" },
				{ value: "5", text: "5" },
				{ value: "6", text: "6" },
				{ value: "7", text: "7" },
				{ value: "8", text: "8" },
				{ value: "9", text: "9" },
				{ value: "10", text: "10" },
				{ value: "11", text: "11" },
				{ value: "12", text: "12" },
				{ value: "13", text: "13" },
				{ value: "14", text: "14" },
				{ value: "15", text: "15" },
				{ value: "16", text: "16" },
				{ value: "17", text: "17" },
				{ value: "18", text: "18" },
				{ value: "19", text: "19" },
				{ value: "20", text: "20" },
				{ value: "21", text: "21" },
				{ value: "22", text: "22" },
				{ value: "23", text: "23" },
				{ value: "24", text: "24" },
			],
			formValid: true,
		};
	},
	methods: {
		dateFormated() {
			const date = this.editData.select_date;
			if (date) {
				let start_Date = MomentJS(date, "YYYY-MM-DD");
				let duration = toSafeInteger(this.editData.total_time);
				let extendfreetime = toSafeInteger(this.editData.time_free);

				if (duration && duration > 0) {
					this.editData.total_time_end = start_Date
						.add(duration, "months")
						.subtract(1, "day")
						.format("YYYY-MM-DD");
					let extend_date = MomentJS(date, "YYYY-MM-DD");
					if (extendfreetime && extendfreetime > 0) {
						this.editData.time_free_end = extend_date
							.add(extendfreetime + duration, "months")
							.subtract(1, "day")
							.format("YYYY-MM-DD");
					} else {
						this.editData.time_free_end = null;
					}
				} else {
					this.editData.total_time_end = null;
					this.editData.time_free_end = null;
					this.editData.time_free = null;
				}
			}
			/* }); */
		},
		updateSEO() {
			let project_id = this.projectId;
			let seo_id = this.seoId;
			let formData = { ...this.editData };

			this.$store
				.dispatch(PUT, {
					url: `seo-update/${project_id}/${seo_id}`,
					data: formData,
				})
				.then(({ data, status }) => {
					console.log({ data });
					if (status) {
						this.$emit("success");
					}
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getData() {
			this.pageLoading = true;
			let seoId = toSafeInteger(this.seoId);
			this.$store
				.dispatch(GET, {
					url: `seo/${seoId}`,
				})
				.then((data) => {
					this.editData = data;

					this.editData = {
						// id: data.id,
						keyword_monthly: data.keyword_monthly,
						keyword_free: data.keyword_free,
						total_time: data.total_time,
						time_free: data.time_free,
						select_date: data.select_date,
						total_time_end: data.total_time_end,
						time_free_end: data.time_free_end,
						remark: data.remark,
						website_urls: data.customer_urls && data.customer_urls[0].website_urls,
						url_target_google:
							data.customer_urls &&
							data.customer_urls[0].url_target_google &&
							data.customer_urls[0].url_target_google.id,
						other_target_google: data.customer_urls && data.customer_urls[0].other_target_google,
					};

					this.projectId = data.project && data.project.id;
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getCustomer() {
			const _this = this;
			_this.$store
				.dispatch(GET, {
					url: "project-setting",
				})
				.then((data) => {
					// _this.serverProvider = data.serverProvider;
					// _this.serverWeb = data.server;
					// _this.payment_list = data.payment_list;
					_this.target_list = data.target_list;
					// this.customerData = data.customers;
					/* this.getCustomerSalesPeson(); */
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		closeDialog() {
			this.$emit("close");
		},
	},
	mounted() {
		this.getData();
		this.getCustomer();
	},
};
</script>
